<header class="auth-header">
    <div class="container">
        <a [routerLink]="['/'+ routesConstants.HOME_NAVIGATION]">
            <img src="assets/images/wedshed-logo.svg" width="136" height="29" alt="Wedshed logo">
        </a>
    </div>
</header>

<!-- <section class="auth-wrap" style="display: none;">
    <div class="auth-left-panel">
        <div class="auth-vector">
            <img src="assets/images/couple-vector.png" width="968" height="932" alt="couple-vector">
        </div>
        <div class="auth-logo">
            <a [routerLink]="['/'+ routesConstants.HOME_NAVIGATION]">
                <img src="assets/images/wedshed-logo.svg" width="136" height="29" alt="wedshed-logo">
            </a>
        </div>
    </div>
    <div class="auth-right-panel">
        <div class="auth-box">
            <div class="auth-box-head">
                <h2 class="section-title">Let’s grab your details</h2>
            </div>
            <form>
                <div class="form-group">
                    <label>Your first name</label>
                    <input class="form-control" type="text" placeholder="Enter first name">
                </div>

                <div class="form-group">
                    <label>Mobile number <small>Used only for sign up</small></label>
                    <input class="form-control" type="text" placeholder="000 000 000">
                </div>

                <div class="form-group">
                    <label>Email address</label>
                    <input class="form-control" type="email" placeholder="Enter email">
                </div>

                <div class="form-group">
                    <label>Password</label>
                    <input class="form-control" type="password" placeholder="Enter password">
                </div>

                <div class="form-group">
                    <label>Confirm password</label>
                    <input class="form-control" type="password" placeholder="Re-enter password">
                </div>

                <div class="form-group">
                    <label>Anything else you’d like to add? <span class="optional">(optional)</span></label>
                    <textarea class="form-control" placeholder="Start typing here"></textarea>
                </div>

                <div class="auth-links acc-links">
                    <p>Already have an account? <a href="/">Sign in
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.73999 12.3701H17.4399" stroke="#7093FF" stroke-miterlimit="10"
                                    stroke-linecap="round" />
                                <path d="M13.4399 16.3701L17.4399 12.3701L13.4399 8.37012" stroke="#7093FF"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </a></p>
                    <p>Are you a business? <a href="/">Sign up here
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.73999 12.3701H17.4399" stroke="#7093FF" stroke-miterlimit="10"
                                    stroke-linecap="round" />
                                <path d="M13.4399 16.3701L17.4399 12.3701L13.4399 8.37012" stroke="#7093FF"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </a></p>
                </div>

                <div class="auth-action">
                    <button class="secondary-btn" type="button">
                        <img src="assets/images/arrow-left-icon.svg" width="13" height="10" alt="arrow-left-icon">
                        <span>Back</span>
                    </button>
                    <button class="primary-btn" type="button">
                        Create account <img src="assets/images/arrow-right-white-icon.svg" width="13" height="10"
                            alt="button-right-icon">
                    </button>
                </div>

            </form>
        </div>
    </div>
</section> -->

<section class="auth-step-wrap">
    @if (!isSubmitAndShowSuccess) {
        <div class="auth-box">
            <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
                @if (currentStep === 0) {
                    <ng-container formGroupName="step1">
                        <div class="auth-box-head">
                            <h1 class="section-title">Let’s grab some details.</h1>
                            <p>We just need your details so we can get in touch.</p>
                        </div>
                        <div class="form-group-box">
                            <div class="form-group">
                                <label>Business type</label>
                                <div class="form-group-radio-2 custom-radio-btn">
                                    <label class="custom-radio">
                                        <input type="radio" name="businessType" formControlName="businessType"
                                               value="venue"
                                               (change)="businessTypeChange($event)">
                                        <span class="checkmark"></span>
                                        A venue
                                    </label>
                                    <label class="custom-radio">
                                        <input type="radio" name="businessType" formControlName="businessType"
                                               value="service"
                                               (change)="businessTypeChange($event)">
                                        <span class="checkmark"></span>
                                        A service
                                    </label>
                                </div>

                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('businessType').hasError('required')) {
                                        <div class="error">
                                            Business type is required
                                        </div>
                                    }
                                }
                            </div>
                        </div>

                        <div class="form-group-box">
                            <div class="form-group">
                                <label>{{ selectedServiceType == commonConstants.BUSINESS_TYPE_SERVICE ? 'Business name' : 'Venue name' }}</label>
                                <input class="form-control" type="text" formControlName="name" placeholder="Enter name">
                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('name').hasError('required')) {
                                        <div class="error">
                                            Name is required
                                        </div>
                                    }
                                }
                            </div>
                        </div>

                        <div class="form-group-box">
                            <h2 class="form-group-box-title">Location</h2>
                            <div class="form-group">
                                <label>Country</label>
                                <ng-select [items]="countryList" bindLabel="name" class="form-control-ng-select"
                                           bindValue="id"
                                           placeholder="Start typing" formControlName="countryId"
                                           (change)="onSelectCountry()">
                                </ng-select>

                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('countryId').hasError('required')) {
                                        <div class="error">
                                            Country is required
                                        </div>
                                    }
                                }
                            </div>

                            <div class="form-group">
                                <label>Region</label>
                                <ng-select [items]="regionList" bindLabel="name" class="form-control-ng-select"
                                           bindValue="id"
                                           placeholder="Start typing" formControlName="regionId"
                                           (change)="onSelectRegion()"
                                           [groupBy]="'state'">
                                </ng-select>

                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('regionId').hasError('required')) {
                                        <div class="error">
                                            Region is required
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                        <input class="form-control" type="hidden" formControlName="stateId">

                        @if (selectedServiceType == commonConstants.BUSINESS_TYPE_SERVICE) {
                            <div class="form-group-box">
                                <div class="form-group">
                                    <label>Wedding service type</label>
                                    <ng-select [items]="serviceTypes" bindLabel="name" class="form-control-ng-select"
                                               bindValue="id"
                                               placeholder="Select" formControlName="serviceType" [multiple]="true"
                                               [closeOnSelect]="false"
                                               maxSelectedItems="5">
                                    </ng-select>

                                    @if (isNextOrSubmit) {
                                        @if (getCurrentGroup.get('serviceType').hasError('required')) {
                                            <div class="error">
                                                Service type is required
                                            </div>
                                        }
                                    }
                                </div>
                            </div>
                        }

                        <div class="form-group-box">
                            <h2 class="form-group-box-title">A bit about you</h2>
                            <div class="form-group-col-2">
                                <div class="form-group">
                                    <label>Years in the business</label>
                                    <input class="form-control" type="number" min="0" formControlName="yearsInBusiness"
                                           placeholder="Enter number">

                                    @if (isNextOrSubmit) {
                                        @if (getCurrentGroup.get('yearsInBusiness').hasError('required')) {
                                            <div class="error">
                                                Years in the business is required
                                            </div>
                                        }
                                    }
                                </div>

                                <div class="form-group">
                                    <label>Number of annual bookings</label>
                                    <input class="form-control" type="number" min="0"
                                           formControlName="annualBookingNumbers"
                                           placeholder="Enter number">

                                    @if (isNextOrSubmit) {
                                        @if (getCurrentGroup.get('annualBookingNumbers').hasError('required')) {
                                            <div class="error">
                                                Number of annual bookings is required
                                            </div>
                                        }
                                    }
                                </div>
                            </div>
                            <label class="custom-radio">
                                <input type="checkbox" formControlName="isGettingStarted"
                                       (change)="isGettingStartedChange($event)">
                                <span class="checkmark"></span>
                                We’re just getting started
                            </label>
                        </div>

                        <div class="form-group-box mb-0">
                            <h2 class="form-group-box-title">Any relevant links</h2>
                            <div class="form-group">
                                <label>Website URL</label>
                                <input class="form-control" type="text" formControlName="websiteUrl"
                                       placeholder="Enter URL">

                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('websiteUrl').hasError('pattern')) {
                                        <div class="error">
                                            Website URL is not a valid
                                        </div>
                                    }
                                }
                            </div>

                            <div class="form-group insta-field">
                                <label>Instagram <span class="optional">(optional)</span></label>
                                <span class="at-icon">&#64;</span>
                                <input class="form-control" type="text" formControlName="instagramUserName"
                                       placeholder="username">

                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('instagramUserName').hasError('cannotContainSpace')) {
                                        <div class="error">
                                            Instagram username cannot contain space
                                        </div>
                                    }
                                }
                            </div>
                        </div>

                        <!-- <div class="auth-add-another">
                            <button class="secondary-btn" type="button">
                                <img src="assets/images/plus-icon.svg" width="12" height="12" alt="plus-icon"> Add another
                                business
                            </button>
                        </div> -->
                    </ng-container>
                }

                @if (currentStep === 1) {
                    <ng-container formGroupName="step2">
                        <div class="auth-box-head">
                            <h2 class="section-title">What are you hoping to achieve with Wedshed?</h2>
                            <p>Select all that apply </p>
                        </div>

                        @if (businessGoals && businessGoals.length > 0) {
                            <div class="form-group mb-0">
                                <div class="auth-achieve-list">
                                    @for (row of businessGoals; track row; let i = $index) {
                                        <label [ngClass]="{'active': selectedBusinessTypes.includes(row.id)}"
                                               (click)="addBusinessType(row.id)">{{ row.name }}</label>
                                    }
                                </div>
                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('achieveReason')?.touched &&
                                    getCurrentGroup.get('achieveReason')?.hasError('required')) {
                                        <div class="error">
                                            Please select at least one
                                        </div>
                                    }
                                }
                            </div>
                        }
                    </ng-container>
                }

                @if (currentStep === 2) {
                    <ng-container formGroupName="step3">
                        <div class="auth-box-head">
                            <h2 class="section-title">Great, one last thing.</h2>
                            <p>We just need your details so we can get in touch.</p>
                        </div>

                        <div class="form-group-box">
                            <div class="form-group">
                                <label>Your first name</label>
                                <input class="form-control" type="text" formControlName="firstName"
                                       placeholder="Enter first name">

                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('firstName')?.touched &&
                                    getCurrentGroup.get('firstName')?.hasError('required')) {
                                        <div class="error">
                                            First name is required
                                        </div>
                                    }
                                }
                            </div>
                            <div class="form-group">
                                <label>Your last name</label>
                                <input class="form-control" type="text" formControlName="lastName"
                                       placeholder="Enter last name">
                            </div>
                        </div>

                        <div class="form-group-box">
                            <div class="form-group">
                                <label>Email address <span class="optional">(this will be your username)</span></label>
                                <input class="form-control" type="text" formControlName="emailAddress"
                                       placeholder="Enter business or work email">

                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('emailAddress')?.touched &&
                                    getCurrentGroup.get('emailAddress')?.hasError('required')) {
                                        <div class="error">
                                            Email address is required
                                        </div>
                                    } @else if (getCurrentGroup.get('emailAddress')?.touched &&
                                    !getCurrentGroup.get('emailAddress')?.hasError('required') &&
                                    getCurrentGroup.get('emailAddress')?.hasError('pattern')) {
                                        <div class="error">
                                            Email address is not a valid
                                        </div>
                                    }
                                }
                            </div>
                        </div>

                        <div class="form-group-box">
                            <div class="form-group">
                                <label>Mobile number</label>
                                <div class="mobile-no-input">
                                    <ng-select [items]="countryCodes.CODE" bindLabel="name"
                                               class="form-control-ng-select"
                                               bindValue="dialCode" formControlName="countryIdForMobile">

                                        <!-- Custom template for displaying selected value (show emoji) -->
                                        <ng-template ng-label-tmp let-item="item">
                                            {{ item.emoji }}
                                        </ng-template>

                                        <!-- Custom template for dropdown list (show name) -->
                                        <ng-template ng-option-tmp let-item="item">
                                            {{ item.name }}
                                        </ng-template>

                                    </ng-select>
                                    <input class="form-control" type="text" formControlName="mobileNumber"
                                           placeholder="Mobile number" [imask]="{mask: '0000 000 000',lazy:false}"
                                           [unmask]="true">
                                </div>

                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('mobileNumber')?.touched &&
                                    getCurrentGroup.get('mobileNumber')?.hasError('required')) {
                                        <div class="error">
                                            Mobile number is required
                                        </div>
                                    } @else if (getCurrentGroup.get('mobileNumber')?.touched &&
                                    !getCurrentGroup.get('mobileNumber')?.hasError('required') &&
                                    getCurrentGroup.get('mobileNumber')?.hasError('pattern')) {
                                        <div class="error">
                                            Mobile number is not a valid
                                        </div>
                                    }
                                }
                            </div>
                        </div>

                        <div class="form-group-box">
                            <div class="form-group">
                                <label>Anything else you’d like to add? <span class="optional">(optional)</span></label>
                                <textarea class="form-control" type="text" formControlName="description"
                                          placeholder="Description"></textarea>
                            </div>
                        </div>
                        <div class="form-group-box mb-0">
                            <div class="form-group">
                                <re-captcha
                                    (resolved)="onCaptchaResolved($event)"
                                    [siteKey]="commonConstants.GOOGLE_CAPTCHA_KEY">
                                </re-captcha>
                                @if (isNextOrSubmit) {
                                    @if (getCurrentGroup.get('recaptcha')?.touched &&
                                    getCurrentGroup.get('recaptcha')?.hasError('required')) {
                                        <div class="error">
                                            Please verify the CAPTCHA.
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                    </ng-container>
                }

                <div class="auth-action">
                    <button class="secondary-btn" type="button" [attr.disabled]="currentStep == 0 ? true : null"
                            (click)="prevStep()">
                        <img src="assets/images/arrow-left-icon.svg" width="13" height="10" alt="Left arrow icon">
                        <span>Back</span>
                    </button>

                    <div class="auth-pagination">
                        <span [class.active]="currentStep === 0"></span>
                        <span [class.active]="currentStep === 1"></span>
                        <span [class.active]="currentStep === 2"></span>
                    </div>

                    @if (currentStep == 2) {
                        <button class="primary-btn" type="submit">
                            Submit <img src="assets/images/arrow-right-white-icon.svg" width="13" height="10"
                                        alt="Right icon">
                        </button>
                    } @else {
                        <button class="primary-btn" type="button" [attr.disabled]="currentStep == 2 ? true : null"
                                (click)="nextStep()">
                            Next <img src="assets/images/arrow-right-white-icon.svg" width="13" height="10"
                                      alt="Right icon">
                        </button>
                    }
                </div>
            </form>
        </div>
    } @else {
        <div class="notification-box">
            <div class="notification-box-icon">
                <img src="assets/images/envelope-circle-icon.png" width="48" height="48" alt="Envelope circle icon">
            </div>
            <h2>Profile request sent</h2>
            <p>Thank you for considering us for the hosting of your {{ selectedServiceType }}. We’ll be in touch soon
                regarding the status of your Wedshed profile.</p>
            <p>All the best and speak soon,</p>
            <p>Mel & Amy 👋</p>
            <a [routerLink]="['/'+ routesConstants.HOME_NAVIGATION]" class="primary-btn">Back to Wedshed</a>
            <div class="notification-box-action">
                <p>Have some questions?</p>
                <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_VENDORS_NAVIGATION]" class="secondary-btn">Vendor
                    FAQs</a>
                <a [routerLink]="['/'+ routesConstants.HOW_IT_WORKS_VENUES_NAVIGATION]" class="secondary-btn">Venue
                    FAQs</a>
            </div>
        </div>
    }

    <!-- <div class="notification-box" style="display: none;">
        <div class="notification-box-icon">
            <img src="assets/images/mail-box-icon.png" width="49" height="48" alt="mail-box-icon.png">
        </div>
        <h2>Confirm your email</h2>
        <p>Before we get into the fun stuff, we want to be 100% sure it's you, so we have sent a email to
            luciana.s&#64;gmail
            .com.</p>
        <p>Simply click the link sent in the email to continue setting up your registry</p>
        <div class="notification-box-action">
            <p>Didn’t get an email?</p>
            <a href="/" class="secondary-btn">Send again</a>
        </div>
    </div> -->

    <!--  Mobile view logo-->
    <div class="auth-logo">
        <a [routerLink]="['/'+ routesConstants.HOME_NAVIGATION]">
            <img src="assets/images/wedshed-logo.svg" width="136" height="29" alt="Wedshed logo">
        </a>
    </div>
</section>
